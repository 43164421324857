









import Vue from 'vue';
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;

export default Vue.component('SOPrice', {
    components: {
        QTranslate: () => import('../elements/QTranslate.vue'),
        SOSimplePrice: () => import('./SOSimplePrice.vue'),
    },
    props: {
        prices: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isSotozei() {
            return !!this.prices.exclude;
        },
        showPrice() {
            const price = this.prices.include;

            return price.toLocaleString();
        },
        showLabel() {
            return translate('lang-0003', '税込');
        },
    },
})
